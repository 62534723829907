// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/blur-background.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_y-HQU{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:100% auto;-webkit-clip-path:inset(0 0 0 0 round var(--border-radius-large));clip-path:inset(0 0 0 0 round var(--border-radius-large));display:flex;flex-direction:column;flex-grow:1;margin-left:1rem;margin-right:1rem;position:relative;width:calc(100% - 2rem)}.intro_L417y{display:flex;margin:8rem auto;max-width:66%;z-index:1}.intro_L417y.isMobile_kD874{margin:4rem 1rem;max-width:none}.alignRight_yvtkQ{display:flex;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_y-HQU",
	"intro": "intro_L417y",
	"isMobile": "isMobile_kD874",
	"alignRight": "alignRight_yvtkQ"
};
module.exports = ___CSS_LOADER_EXPORT___;
