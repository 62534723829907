
import BfeForAddress from "~/graphql/Property/BfeForAddress.gql";
import { mapWritableState } from "pinia";
import { useChromeStore } from "~/composables/stores/chrome";

export default {
  props: {
    // Address object, mutually exclusive with addressString
    address: {
      type: Object,
      default: null,
    },

    // Address string if no address object is present
    addressString: {
      type: String,
      default: null,
    },

    bfeNumber: {
      type: Number,
      default: 0,
    },

    allowSearchForBfe: {
      type: Boolean,
      default: true,
    },

    // If true, component will emit click event instead of navigating to property
    customClick: {
      type: Boolean,
      default: false,
    },

    filter: {
      type: String,
      default: "addressLong",
    },

    // For tracking. Specifies the origin of this component.
    origin: {
      type: String,
      default: "propertyLink",
    },

    // Override copy props
    copyType: {
      type: String,
      default: "body",
    },

    copySize: {
      type: String,
      default: "medium",
    },
  },

  computed: {
    formattedAddress: function () {
      if (this.address != null) {
        return this.$options.filters[this.filter](this.address);
      } else if (this.addressString != null) {
        return this.addressString;
      } else {
        return this.$t("NO_ACCESS_ADDRESS");
      }
    },

    ...mapWritableState(useChromeStore, { chromeBlockUI: "blockUI" }),
  },

  methods: {
    async initSearch(e) {
      if (this.customClick) {
        this.$emit("click", e);
        return;
      }
      let options = {
        per_side: 3,
        type: "adresse",
        supplerendebynavn: true,
        stormodtagerpostnumre: true,
        multilinje: true,
        fuzzy: "",
        startfra: "adgangsadresse",
      };
      if (this.address) {
        options.q = this.$options.filters.addressLong(this.address);
      } else {
        options.q = this.addressString;
      }

      const resp = await this.$axios.$get("https://dawa.aws.dk/autocomplete?", { params: options });

      const bfe = await this.$apollo.query({
        query: BfeForAddress,
        variables: {
          addressId: resp[0].data.adgangsadresseid != null ? resp[0].data.id : null,
          accessAddressId: resp[0].data.adgangsadresseid != null ? resp[0].data.adgangsadresseid : resp[0].data.id,
        },
      });

      if (bfe.data.bfeForAddress != null) {
        this.navigateToProperty(bfe.data.bfeForAddress);
      }
    },

    navigateToProperty(bfeNumber) {
      this.chromeBlockUI = false;

      const bfe = bfeNumber || this.bfeNumber || null;

      if (this.customClick) {
        this.$emit("click");
        return;
      } else if (!bfe) {
        this.initSearch();
      }
      this.$router.push({
        name: "explore-property-id",
        params: { id: bfe },
      });

      this.$amplitude.track({
        event_type: "Property lookup",
        event_properties: {
          bfeNumber: bfeNumber,
          route: this.origin,
        },
      });
    },
  },
};
